.current-app-widget .Polaris-Card .Polaris-Card__Section:not(:first-child):hover {
  background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
  /* border-color: #c4cdd5; */
}
.current-app-action-item___link {
  color: inherit;
}
.current-app-action-item___wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.current-app-action-item___wrapper .Polaris-Icon {
  width: 22px;
  height: 22px;
}
.current-app-action-item___content {
  display: flex;
  align-items: center;
}
.current-app-action-item___icon {
  width: 50px;
  height: 50px;
  margin-right: 1.5rem;
}
