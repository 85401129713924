.app-list___inner {
  display: flex;
  flex-wrap: wrap;
}
.app-list___item {
  display: flex;
  padding: 2rem;
  flex-basis: 50%;
}
.app-list___item .app-list___link {
  text-decoration: none;
  color: var(--p-interactive, #006fbb);
  position: relative;
  top: -3px;
}
.app-list___item .app-list___link:hover {
  color: var(--p-interactive-hovered, #084e8a);
  text-decoration: underline;
}
.app-list___item p {
  margin-bottom: 10px;
}
.app-list___icon {
  width: 3rem;
  border-radius: 3px;
  height: 3rem;
  margin-right: 1.5rem;
}
