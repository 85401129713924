.preview-container-inner {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
}

.preview-container {
  /* height: calc(100vh - 4rem); */
  text-align: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  position: sticky;
  top: 0;
  /* because modals have z-index of 519 */
  z-index: 518;
  margin-bottom: 2rem;
}

.preview-container .top-bar {
  height: 30px;
  border-radius: 5px 5px 0 0;
  border-top: thin solid #eaeae9;
  border-bottom: thin solid #dfdfde;
  background: linear-gradient(#e7e7e6, #e2e2e1);
}

.preview-container .circle {
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  border-radius: 0.45em;
  background-color: #eee;
  margin: 0.4em 0.15em;
}
.preview-container .circle:first-child {
  background-color: rgb(255, 86, 79);
}
.preview-container .circle:nth-child(2) {
  background-color: rgb(255, 183, 42);
}
.preview-container .circle:nth-child(3) {
  background-color: rgb(37, 198, 58);
}

.preview-container .circles {
  display: flex;
  justify-content: space-between;
  color: #949494;
  margin: 5px 11px;
}
/* sticky hack */
.sticky-preview-container .Polaris-Layout__Section:last-child {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
}
